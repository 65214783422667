// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { toPascalCase, smartypants } from '../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/project/header';
import Footer from '../components/project/footer';
import ImageBlock from '../components/project-image/project-image';
import GalleryBlock from '../components/project-gallery';
import CopyBlock from '../components/project-copy';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './project.module.scss';


const BlogPostTemplate = ( { data, pageContext, location } ) => {
	const post = data.portfolioJson;
	const { title, description, thumbnail, content } = post;
	const siteTitle = data.site.siteMetadata.title;
	const { previous, next } = pageContext;

	const [ ref, inView, entry ] = useInView();

	return (
		<Layout location={location} title={siteTitle} projectTitle={title} previousProject={previous ? previous.fields.slug : null} nextProject={next ? next.fields.slug : null} projectTitleInView={inView} colophon={data.site.siteMetadata.colophon}>
			<SEO
				title={title}
				description={description}
				metaThumb={( post.shareImage && post.shareImage.childImageSharp && post.shareImage.childImageSharp.original ) ? post.shareImage.childImageSharp.original : null}
			/>
			<article itemScope itemType="https://schema.org/CreativeWork">
				<Header title={title} thumbnail={thumbnail.detail} headingObserverRef={ref} />
				<meta itemProp="abstract" content={description}/>
				<div itemProp="author" itemScope itemType="https://schema.org/Person">
					<meta itemProp="name" content={data.site.siteMetadata.author}/>
					<meta itemProp="url" content={data.site.siteMetadata.siteUrl}/>
				</div>
				<main className={styles.main}>
					<div className={styles.meta}>
						<div className={styles.metaInner}>
							{content && content.intro && Array.isArray( content.intro ) && (
								content.intro.map( ( copy, index ) => (
									<CopyBlock block={{ content: copy }} modifier="intro" key={index} />
								) )
							)}
							{content && content.cta && content.cta.text && content.cta.url && (
								<a className={styles.cta} href={content.cta.url} target="_blank" rel="noopener noreferrer">
									<link itemProp="url" href={content.cta.url}/>
									<span>{content.cta.text}</span>
								</a>
							)}

							{content && content.contributors && (
								<div className={styles.contributors}>
									<h2>Contributors</h2>
									<ul>
										{content.contributors.map( ( contributor, index ) => (
											<li key={index} itemProp="contributor" itemScope itemType="https://schema.org/Person">
												{contributor.title && ( `${smartypants( contributor.title )} ` )}

												{contributor.people.length && contributor.people.map( ( person, index, arr ) => (
													person.url && ( person.firstName || person.lastName ) ? (
														<React.Fragment key={index}>
															<a href={person.url} target="_blank" rel="noopener noreferrer">
																<link itemProp="url" href={person.url}/>
																<span itemProp="name">
																	{person.firstName && ( `${smartypants( person.firstName )} ` )}
																	{person.lastName && ( `${smartypants( person.lastName )}` )}
																</span>
															</a>
															{arr.length - 1 !== index ? ', ' : '' }
														</React.Fragment>
													) : (
														<React.Fragment key={index}>
															<span itemProp="name">
																{person.firstName && ( `${smartypants( person.firstName )} ` )}
																{person.lastName && ( `${smartypants( person.lastName )}` )}
															</span>
															{arr.length - 1 !== index ? ', ' : '' }
														</React.Fragment>
													)
												) )}
											</li>
										) )}
									</ul>
								</div>
							)}


							{content && content.completedAt && (
								<div className={styles.contributors}>
									<p itemScope itemType="https://schema.org/Organization">{content.completedAt.completedAtTextOverride ? content.completedAt.completedAtTextOverride : 'Completed at'} {content.completedAt.url ? <a href={content.completedAt.url} target="_blank" rel="noopener noreferrer"><link itemProp="url" href={content.completedAt.url} /><span itemProp="name">{content.completedAt.organization}</span></a> : <span itemProp="name">{content.completedAt.organization}</span>}</p>
								</div>
							)}


						</div>
					</div>
					<div className={styles.blocks}>
						{content && content.blocks && ( content.blocks.map( ( block, index ) => (
							block.type === 'image' ? (
								<ImageBlock block={block} sharedStyle={styles[ `block${toPascalCase( block.type )}` ]} key={index} />
							) : block.type === 'gallery' ? (
								<GalleryBlock block={block} sharedStyle={styles[ `block${toPascalCase( block.type )}` ]} key={index} />
							) : block.type === 'copy' ? (
								<CopyBlock block={block} modifier="content" sharedStyle={styles[ `block${toPascalCase( block.type )}` ]} key={index} />
							) : ''
						) ) )}
					</div>
				</main>

				<Footer previous={previous} next={next}/>
			</article>

		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				author
				siteUrl
				colophon {
					heading
					content
				}
			}
		}
		portfolioJson(fields: { slug: { eq: $slug } }) {
			id
			title
			description
			shareImage {
				childImageSharp {
					original {
						width
						height
						src
					}
				}
			}
			thumbnail {
				detail {
					type
					file {
						...projectImage
					}
					alt
					caption
				}
			}
			content {
				intro
				cta {
					text
					url
				}
				contributors {
					title
					people {
						firstName
						lastName
						url
					}
				}
				completedAt {
					completedAtTextOverride
					organization
					url
				}
				blocks {
					type
					subhead
					modifier # for image and gallery types only
					images { # for image and gallery types only
						file {
							...projectImage
						}
						alt
						caption
					}
					caption # for image and gallery types only
					content # for copy type only
				}
			}
		}
	}
`;
