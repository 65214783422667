// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import Swiper from 'react-id-swiper';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { toPascalCase, formatAltText, isDevelopment, smartypants } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Img from 'gatsby-image';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './project-gallery.module.scss';
import 'swiper/swiper.scss';

const availableGalleryTypes = [ 'large', 'small' ];

const ProjectGallery = ( { block, sharedStyle = '' } ) => {
	const { modifier, images, subhead } = block;
	const galleryType = availableGalleryTypes.includes( modifier ) ? modifier : 'large';

	let imgSizes;

	if ( galleryType === 'small' ) {
		imgSizes = '( min-width: 1440px ) 36vw, ( min-width: 768px ) 66vw, 84vw';
	} else {
		imgSizes = '( min-width: 1440px ) 87vw, ( min-width: 768px ) 83vw, 84vw';
	}

	const params = {
		cssMode: false,
		slidesPerView: 'auto',
		loop: false,
		grabCursor: true,
		slideToClickedSlide: true,
		spaceBetween: 16,
		breakpoints: {
			700: {
				spaceBetween: 20,
			},
			1440: {
				spaceBetween: 21,
			},
		},
		roundLengths: true,
		freeMode: true,
	};

	return(
		!images || !Array.isArray( images ) || !images.length ? (
			<div className={styles.error}>No images displayed; please include images in valid JSON array format.</div>
		) : (
			<div className={`${styles.block} ${styles[ `block${toPascalCase( modifier )}` ]} ${sharedStyle && sharedStyle}`}>
				{!availableGalleryTypes.includes( modifier ) && isDevelopment() ? (
					<div className={styles.error}>👇 The gallery modifier <code>{modifier}</code> is invalid. Please define a valid gallery modifier; either
						{availableGalleryTypes.map( ( type, index, arr )=> (
							<React.Fragment key={index}>
								<code>{type}</code>
								{arr.length - 1 !== index ? ', ' : '' }
							</React.Fragment>
						) )}
					.</div>
				) : ''}
				{subhead && <h2 className={styles.subhead} dangerouslySetInnerHTML={{ __html: subhead }}/>}
				<Swiper {...params}>
					{images.map( ( image, index ) => (
						<figure key={index}>
							{!!image.file && !!image.file.childImageSharp ? (
								<>
									{image.file.extension === 'png' ? (
										<Img
											fluid={{
												...image.file.childImageSharp.fluid,
												sizes: imgSizes,
												srcWebp: null,
												srcSetWebp: null,
											}}
											alt={formatAltText( image.alt )}
										/>
									)
										: (
											<Img
												fluid={{
													...image.file.childImageSharp.fluid,
													sizes: imgSizes,
												}}
												alt={formatAltText( image.alt )}
											/>

										)}
								</>
							) : (
								<>
									<img className={styles.unsupportedImg} src={image.file.publicURL} alt={formatAltText( image.alt )}/>
								</>
							) }
							{image.caption && (
								<figcaption className={styles.caption}>{smartypants( image.caption )}</figcaption>
							)}
						</figure>
					) )}
				</Swiper>
			</div>
		)
	);
};

export default ProjectGallery;
