// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { toPascalCase, formatAltText, isDevelopment, getSizesString, smartypants, imageExtensions, videoExtensions } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Img from 'gatsby-image';
import MutedMp4 from '../muted-mp4/muted-mp4';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './header.module.scss';


const availableImageTypes = ['image', 'browser'];
const availableVideoTypes = ['video'];
const availableThumbnailTypes = [ ...availableImageTypes, ...availableVideoTypes ];

const ProjectHeader = ( { title, thumbnail, headingObserverRef } ) => {
	const thumbnailType = availableThumbnailTypes.includes( thumbnail.type ) ? thumbnail.type : 'image';
	const imgSizes = thumbnailType === 'image' ? '100vw' : `( min-width: 1440px ) ${getSizesString(
		'100vw', 21, 21, 16, 12 )}, ( min-width: 768px ) ${getSizesString(
		'100vw', 20, 20, 1, 1 )}, ${getSizesString( '100vw', 16, 16, 1, 1 )}`;

	return (
		<header className={styles.header}>
			<h1 className={styles.heading} ref={headingObserverRef}>
				<span itemProp="name">{smartypants( title )}</span>
			</h1>

			{thumbnail.file ? (
				<figure className={`${styles.figure} ${styles[ `figure${toPascalCase( thumbnailType )}` ]}`}>
					{(!availableThumbnailTypes.includes( thumbnail.type ) || ![...imageExtensions, ...videoExtensions].includes(thumbnail.file.extension)) && isDevelopment() ? (
						<div className={styles.error}>Please define a valid thumbnail type for your thumbnail; either <code>image</code> or <code>browser</code>. If you want to display a video, set the thumbnail type to <code>video</code>.</div>
					) : ''}
					<div className={styles.imgWrap}>
						<div className={styles.img} >
							<div className={styles.frame}>
								{!!thumbnail.file && !!thumbnail.file.childImageSharp && imageExtensions.includes(thumbnail.file.extension) ? (
									<Img
										className={''}
										fluid={{
											...thumbnail.file.childImageSharp.fluid,
											sizes: imgSizes,
										}}
										itemProp="image"
										alt={formatAltText( thumbnail.alt )}
									/>
								) : !!thumbnail.file && videoExtensions.includes(thumbnail.file.extension) ? (
									<MutedMp4 src={thumbnail.file.publicURL}></MutedMp4>
								) : (
									<img src={thumbnail.file.publicURL} alt={formatAltText( thumbnail.alt )} itemProp="image"/>
								) }
							</div>
						</div>
					</div>
					{thumbnail.caption ? (
						<figcaption className={styles.caption}>{smartypants( thumbnail.caption )}</figcaption>
					) : ''}
				</figure>
			) : null}
		</header>
	);
};

ProjectHeader.defaultProps = {
	title: '(project title is missing)',
};

ProjectHeader.propTypes = {
	title: PropTypes.string.isRequired,
	thumbnail: PropTypes.shape( {
		type: PropTypes.oneOf( availableThumbnailTypes ).isRequired,
		file: PropTypes.shape( {
			childImageSharp: PropTypes.object,
		} ).isRequired,
		alt: PropTypes.string,
		caption: PropTypes.string,
	} ),
	headingObserverRef: PropTypes.oneOfType( [
		PropTypes.func,
		PropTypes.shape( { current: PropTypes.any } ),
	] ),
};

export default ProjectHeader;
