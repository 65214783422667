// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';


const MutedMp4 = ( { src } ) => (
	<video autoPlay muted loop playsInline>
		<source src={src} type="video/mp4" />
	</video>
);

MutedMp4.propTypes = {
	src: PropTypes.string.isRequired,
};

export default MutedMp4;
