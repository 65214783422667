// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { toPascalCase, formatAltText, isDevelopment, getSizesString, prependSiteUrl, smartypants, imageExtensions, videoExtensions } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Img from 'gatsby-image';
import MutedMp4 from '../muted-mp4/muted-mp4';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './project-image.module.scss';


const availableImageTypes = [ 'large', 'small', 'full', 'browser-large', 'browser-small', 'tablet', 'phone' ];

const ProjectImage = ( { block, sharedStyle = '' } ) => {
	const { modifier, images, caption, subhead } = block;
	const imageType = availableImageTypes.includes( modifier ) ? modifier : 'large';

	let imgSizes;

	if ( imageType === 'large' ) {
		if ( images.length > 1 ) {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 21, 21, 16, 6 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 20, 20, 2, 1 )}, ${getSizesString( '100vw', 16, 16, 2, 1 )}`;
		} else {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 21, 21, 16, 12 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 20, 20, 1, 1 )}, ${getSizesString( '100vw', 16, 16, 1, 1 )}`;
		}
	} else if ( imageType === 'small' ) {
		imgSizes = `( min-width: 1440px ) ${getSizesString(
			'100vw', 21, 21, 16, 6 )}, ( min-width: 768px ) ${getSizesString(
			'100vw', 20, 20, 6, 4 )}, ${getSizesString( '100vw', 16, 16, 6, 4 )}`;
	} else if ( imageType === 'full' ) {
		if ( images.length === 1 ) {
			imgSizes = '100vw';
		} else if ( images.length % 3 === 0 ) {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 0, 21, 3, 1 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 0, 20, 3, 1 )}, ${getSizesString( '100vw', 0, 16, 3, 1 )}`;
		} else {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 0, 21, 2, 1 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 0, 20, 2, 1 )}, ${getSizesString( '100vw', 0, 16, 2, 1 )}`;
		}
	} else if ( imageType === 'browser-large' ) {
		if ( images.length === 1 ) {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 21, 21, 16, 12 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 20, 20, 6, 6 )}, ${getSizesString( '100vw', 16, 16, 1, 1 )}`;
		} else if ( images.length % 4 === 0 ) {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 21, 21, 16, 6 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 20, 20, 6, 3 )}, ${getSizesString( '100vw', 16, 16, 1, 1 )}`;
		} else {
			imgSizes = `( min-width: 1440px ) ${getSizesString(
				'100vw', 21, 21, 16, 6 )}, ( min-width: 768px ) ${getSizesString(
				'100vw', 20, 20, 6, 6 )}, ${getSizesString( '100vw', 16, 16, 1, 1 )}`;
		}
	} else if ( imageType === 'browser-small' ) {
		imgSizes = `( min-width: 1440px ) ${getSizesString(
			'100vw', 21, 21, 16, 8 )}, ( min-width: 768px ) ${getSizesString(
			'100vw', 20, 20, 6, 4 )}, ${getSizesString( '100vw', 16, 16, 6, 4 )}`;
	} else if ( imageType === 'tablet' ) {
		imgSizes = `( min-width: 1440px ) ${getSizesString(
			'100vw', 21, 21, 16, 6 )}, ( min-width: 768px ) ${getSizesString(
			'100vw', 20, 20, 6, 3 )}, ${getSizesString( '100vw', 16, 16, 6, 4 )}`;
	} else if ( imageType === 'phone' ) {
		imgSizes = `( min-width: 1440px ) ${getSizesString(
			'100vw', 21, 21, 16, 4 )}, ( min-width: 768px ) ${getSizesString(
			'100vw', 20, 20, 6, 2 )}, ${getSizesString( '100vw', 16, 16, 6, 3 )}`;
	}


	let classNameModifier = '';

	if ( imageType === 'large' ) {
		classNameModifier = images.length > 1 ? styles.hasMultiple : null;
	} else if ( imageType === 'full' ) {
		classNameModifier = images.length === 1 ? styles.isMultiple1 : images.length % 3 === 0 ? styles.isMultiple3 : styles.isMultiple2;
	} else if ( imageType === 'browser-large' ) {
		classNameModifier = images.length === 1 ? styles.isMultiple1 : images.length % 4 === 0 ? styles.isMultiple4 : styles.isMultiple2;
	} else if ( imageType === 'phone' ) {
		classNameModifier = images.length === 1 ? styles.isMultiple1 : images.length % 3 === 0 ? styles.isMultiple3 : styles.isMultiple2;
	}

	return (
		!images || !Array.isArray( images ) || !images.length ? (
			<div className={styles.error}>No images displayed; please include images in valid JSON array format.</div>
		) : (
			<figure className={`${styles.block} ${styles[ `block${toPascalCase( imageType )}` ]}  ${sharedStyle && sharedStyle}`} itemProp="image" itemScope itemType="https://schema.org/ImageObject">
				{!availableImageTypes.includes( modifier ) && isDevelopment() ? (
					<div className={styles.error}>👇 The image modifier <code>{modifier}</code> is invalid. Please define a valid image modifier; either
						{availableImageTypes.map( ( type, index, arr )=> (
							<React.Fragment key={index}>
								<code>{type}</code>
								{arr.length - 1 !== index ? ', ' : '' }
							</React.Fragment>
						) )}
					.</div>
				) : ''}
				{subhead && <h2 className={styles.subhead} dangerouslySetInnerHTML={{ __html: smartypants( subhead ) }}/>}
				<div className={styles.collectionWrap}>
					<div className={`${styles.collection} ${classNameModifier}`}>
						{images.map( ( image, index ) => (
							<div key={index} className={styles.frame}>
								{!!image.file && !!image.file.childImageSharp && imageExtensions.includes(image.file.extension) ? (
									<>
										<meta itemProp="url" content={prependSiteUrl( image.file.childImageSharp.fluid.src )} />
										{image.file.extension === 'png' ? (
											<Img
												fluid={{
													...image.file.childImageSharp.fluid,
													sizes: imgSizes,
													srcWebp: null,
													srcSetWebp: null,
												}}
												alt={formatAltText( image.alt )}
											/>
										)
											: (
												<Img
													fluid={{
														...image.file.childImageSharp.fluid,
														sizes: imgSizes,
													}}
													alt={formatAltText( image.alt )}
												/>

											)}
									</>
								) : !!image.file && videoExtensions.includes(image.file.extension) ? (
									<>
										<MutedMp4 src={image.file.publicURL}></MutedMp4>
										<meta itemProp="url" content={prependSiteUrl( image.file.publicURL )} />
									</>
								) : (
									<>
										<img className={styles.unsupportedImg} src={image.file.publicURL} alt={formatAltText( image.alt )}/>
										<meta itemProp="url" content={prependSiteUrl( image.file.publicURL )} />
									</>
								) }
							</div>
						) )}
					</div>
				</div>
				{caption ? (
					<figcaption className={styles.caption} itemProp="caption">{smartypants( caption )}</figcaption>
				) : ''}
			</figure>
		)
	);
};

ProjectImage.propTypes = {
	block: PropTypes.shape( {
		modifier: PropTypes.oneOf( availableImageTypes ).isRequired,
		images: PropTypes.arrayOf( PropTypes.shape( {
			file: PropTypes.shape( {
				childImageSharp: PropTypes.object,
				publicURL: PropTypes.string.isRequired,
			} ),
			alt: PropTypes.string,
		} ) ).isRequired,
		caption: PropTypes.string,
	} ).isRequired,
	sharedStyle: PropTypes.string,
};

export default ProjectImage;
