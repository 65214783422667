// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import PostFeedItem from '../post-feed-item/post-feed-item';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './footer.module.scss';


const ProjectFooter = ( { previous, next } ) => (
	next || previous ? (
		<footer className={styles.footer}>
			<nav className={styles.nav}>
				<ul className={styles.list}>
					{next && (
						<li className={styles.listItem}>
							<p className={styles.label}>
								<span>Next Project</span>
							</p>
							<PostFeedItem post={next} linkRel="next"/>
						</li>
					)}
					{previous && (
						<li className={styles.listItem}>
							<p className={styles.label}>
								<span>Previous Project</span>
							</p>
							<PostFeedItem post={previous} linkRel="prev"/>
						</li>
					)}
				</ul>
			</nav>
		</footer>
	) : ''
);

ProjectFooter.defaultProps = {
	previous: null,
	next: null,
};

ProjectFooter.propTypes = {
	previous: PropTypes.object,
	next: PropTypes.object,
};

export default ProjectFooter;



