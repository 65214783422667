// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { smartypants } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './styles.module.scss';


const ProjectCopy = ( { block, modifier = '', sharedStyle = '' } ) => {
	const { subhead, content } = block;
	const contentIsArray = !!content && Array.isArray(content)
	const contentIsString = !!content && typeof content === 'string'

	return (
		<div className={`${styles.block} ${modifier && styles[ modifier ]} ${sharedStyle && sharedStyle}`}>
			<div className={styles.markdown}>
				{subhead && <h2 className={styles.subhead} dangerouslySetInnerHTML={{ __html: smartypants( subhead ) }}/>}
				{contentIsString ? <p dangerouslySetInnerHTML={{ __html: smartypants( content ) }}/> : contentIsArray ? content.map((paragraph, index) => <p dangerouslySetInnerHTML={{ __html: smartypants( paragraph ) }} key={index}/>) : ''}
			</div>
		</div>
	);
};

ProjectCopy.propTypes = {
	block: PropTypes.shape( {
		subhead: PropTypes.string,
		content: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string)
		]).isRequired,
	} ).isRequired,
	modifier: PropTypes.string,
	sharedStyle: PropTypes.string,
};

export default ProjectCopy;
